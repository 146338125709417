<template>
  <CCard no-header>
    <CCardBody>
      <h3><CIcon name="cil-pencil" /> {{ operationName }} Closure</h3>
      <CAlert v-if="message" :color="alertType"> {{ message }} </CAlert>
      <hr>
      <h4>General</h4>
      <CRow>
        <CCol>
          <CInput label="Name" type="text" v-model="closure.name" />
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <label>Start time</label>
          <ejs-datetimepicker v-model="closure.startTime" :format="$dateFormatInput" :timeFormat="$dateFormatInput.split(' ').length > 1 ? `${$dateFormatInput.split(' ')[1]} ${$dateFormatInput.split(' ')[2]}` : $dateFormatInput.split(' ')[1]"></ejs-datetimepicker>
        </CCol>
        <CCol>
          <label>End time</label>
          <ejs-datetimepicker v-model="closure.endTime" :format="$dateFormatInput" :timeFormat="$dateFormatInput.split(' ').length > 1 ? `${$dateFormatInput.split(' ')[1]} ${$dateFormatInput.split(' ')[2]}` : $dateFormatInput.split(' ')[1]"></ejs-datetimepicker>
        </CCol>
      </CRow>
      <CRow v-if="!isDataLoading">
        <CCol>
          <label>Weekdays</label>
          <CMultiSelect v-model="closure.days" :multiple="true"
            :selection="true" @update="appendValueAdd($event)" optionsEmptyPlaceholder="No options"
            selectionType="text" :options="options" :selected.sync="closure.days" />
        </CCol>
      </CRow>
      <hr>
      <h4>Experiences</h4>
      <CRow>
        <CCol col="auto">
          <CInputRadio @change="selectExperiences = false" label="All experiences" type="radio" name="types"
                        :checked.sync="allExperiences" />

        </CCol>
        <CCol col="auto">
          <CInputRadio @change="allExperiences = false" label="Select experiences" type="radio" name="types"
                        :checked.sync="selectExperiences" />
        </CCol>
      </CRow>
      <div v-show="selectExperiences">
        <ejs-multiselect
        v-model="closure.experiences"
        placeholder="Choose experiences"
        :dataSource="experiences"
        mode="CheckBox"
        :fields="{ text: 'label', value: 'value' }"
      >
      </ejs-multiselect>
      </div>
      
      <CRow style="padding-top: 15px;">
        <CCol col="6"></CCol>
        <CCol class="d-flex justify-content-end">
          <CButton color="secondary" class="btn-lg" @click="goBack">
            <CIcon name="cil-list" /> Back to the list
          </CButton>

          <CButton
            color="primary"
            @click="save()"
            class="btn-lg"
            style="margin-left: 15px"
          >
            <CIcon name="cil-save" /> Save
          </CButton>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>
  
<script>
import axios from "axios";
import { DateTimePickerComponent } from '@syncfusion/ej2-vue-calendars';

import {
  MultiSelectComponent,
  CheckBoxSelection,
} from "@syncfusion/ej2-vue-dropdowns";
export default {
  name: "AddNewTaxClass",
  data: () => {
    return {
      operationName: "Create New",
      partnerId: localStorage.getItem("partner_id"),
      emptyGuid: "00000000-0000-0000-0000-000000000000",
      token: localStorage.getItem("api_token"),
      experiences: [],
      isDataLoading: false,
      allExperiences: false,
      selectExperiences: false,
      options: [
        {
          value: 1,
          text: 'Monday',
        },
        {
          value: 2,
          text: 'Tuesday',
        },
        {
          value: 3,
          text: 'Wednesday',
        },
        {
          value: 4,
          text: 'Thursday',
        },
        {
          value: 5,
          text: 'Friday',
        },
        {
          value: 6,
          text: 'Saturday',
        },
        {
          value: 7,
          text: 'Sunday',
        },
      ],
      // Alert
      alertType: "danger",
      message: null,

      closure: {
        id: "00000000-0000-0000-0000-000000000000",
        startTime: new Date((new Date()).setUTCHours(0, 0, 0, 0)).toISOString().split('.')[0],
        endTime: new Date((new Date()).setUTCHours(23, 59, 0, 0)).toISOString().split('.')[0],
        days: [1, 2, 3, 4, 5, 6, 7]
      },
    };
  },
  components: {
    "ejs-multiselect": MultiSelectComponent,
    'ejs-datetimepicker': DateTimePickerComponent,
  },
  provide: {
    multiselect: [CheckBoxSelection],
  },
  methods: {
    goBack() {
      if(this.$route.params.experienceId)
      this.$router.push({
        path: `/experiences/create/${this.$route.params.experienceId}?closure=true`,
      });
      else
      this.$router.go(-1);
    },
    appendValueAdd(event) {
      this.closure.days = event;
    },
    getExperiences() {
      let self = this;
      axios
        .get(this.$apiAdress + "/v1/ListItem/ExperienceList")
        .then(function (response) {
          self.experiences = response.data;
          if(self.$route.params.experienceId && self.$route.params.id == self.emptyGuid){
            self.closure.experiences = [self.experiences.find(experience => experience.value == self.$route.params.experienceId).value];
            if(!self.closure.experiences.find(experienceId => experienceId == self.emptyGuid)){
              self.selectExperiences = true;
              self.allExperiences = false;
            }

          }
          if (self.$route.params.id != self.emptyGuid)
            self.get(self.$route.params.id);
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    get(id) {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/Closures/${id}`)
        .then(function (response) {
          self.closure = response.data;
          if(self.closure.experiences.find(experienceId => experienceId == self.emptyGuid))
            self.allExperiences = true;
          else
            self.selectExperiences = true;
          self.isDataLoading = false;
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
          self.isDataLoading = false;
        });
    },
    save() {
      let self = this;
      if(self.allExperiences)
        self.closure.experiences = [self.emptyGuid];
      axios
        .post(`${this.$apiAdress}/v1/Closures`, self.closure)
        .then((response) => {
          self.closure.id = response.data;
                    self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
          self.message = "Successfully created closure.";
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          if(error.response.data.code == 36)
            self.message = "Please select at least one experience.";
          else if(error.response.data.code == 18)
            self.message = "Please add a name.";
          else if(error.response.data.code == 21)
            self.message = "End time must be greater than start time.";
          else
            self.message = error.response.data;
        });
    },
  },
  mounted: function () {
    this.getExperiences();
    if (this.$route.params.id != this.emptyGuid) {
      this.operationName = "Edit";
      this.isDataLoading = true;
    }
    else{
      this.allExperiences = true;
    }
  },
};
</script>
  